import React, { useMemo, useState } from "react";
import BreadCrumb from "../UI/BreadCrumb";
import Button from "../UI/Button";
import WhiteCloud from "../../images/icons/WhiteCloud.svg";
import Logo from "../../images/icons/newLogo.svg";
import TempLogo from "../../images/icons/temp.svg";
import SunLogo from "../../images/icons/sun.svg";
import SunOutline from "../../images/icons/sunOutline.svg";
import HumidityLogo from "../../images/icons/humidity.svg";
import PetLogo from "../../images/icons/pet.svg";
import StarLogo from "../../images/icons/star.svg";
import WaterLogo from "../../images/icons/water.svg";
import OrangeCalendar from "../../images/icons/orangeCalendar.svg";
import FertilizingLogo from "../../images/icons/fertilizing.svg";
import YellowCalendar from "../../images/icons/yellowCalendar.svg";
import ReporttingLogo from "../../images/icons/repotting.svg";
import SoilPot from "../../images/icons/soilPot.svg";
import SoilLogo from "../../images/icons/soil.svg";
import { graphql, navigate } from "gatsby";
import { DOWNLOAD } from "../../assets/Links";
import DownloadButtons from "../UI/DownloadButtons";
import QRCode from "../../images/QRCode.png";
import MemberBadge from "../MemberBadge";
// import RecommendedWater from "../../images/icons/recommendedWater.svg";
import BlueCalendar from "../../images/icons/blueCalendar.svg";
import BlueCup from "../../images/icons/blueCup.svg";
// import { StaticImage } from "gatsby-plugin-image";
import CareCard from "../UI/CareCard";
import Layout from "../Layout";
import DownloadAppBar from "../Layout/DownloadAppBar";
// import FAQS from "../PlantDetail/FAQs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import CommonDiseases from "./commonDiseases";
import {
  isJsonString,
  JsonFunc,
  RemoveFirstLastString,
} from "../../utils/utils";
import { SHOW_ANNOUCEMENT_BANNER } from "../../utils/constant";
import PlantPlaceholder from "../../images/PlantPlaceholder.png";
import RelatedPlants from "../PlantDetail/RelatedPlants";
import ShopPlants from "../PlantDetail/ShopPlants";
const Care = (props) => {
  const careData = props?.data?.allCarePlants?.edges?.[0]?.node;
  const plantData = props?.data?.allPlants?.edges?.[0]?.node;
  const blogs = props?.data?.allGraphCmsPost?.edges?.map((edge) => {
    return edge?.node;
  });

  const [showFullText, setShowFullText] = useState(false);

  const badges = useMemo(
    () => [
      {
        icon: StarLogo,
        label: "Level",
        text: `${plantData?.overviewLevel}`,
        background: `linear-gradient(135deg, rgba(135, 225, 50, 0.15) 0%, rgba(42, 194, 213, 0.15) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        icon: WaterLogo,
        anchorLink: "water",
        label: "Water",
        text: `${plantData?.avgWaterDays} days`,
        background: `linear-gradient(94.5deg, rgba(54, 213, 249, 0.15) 0%, rgba(21, 171, 235, 0.15) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        icon: TempLogo,
        anchorLink: "temperature",
        label: "Temperature",
        text: `${plantData?.overviewMinTemp}° - ${plantData?.overviewMaxTemp}°`,
        background: `linear-gradient(94.5deg, rgba(254, 85, 88, 0.15) 0%, rgba(234, 70, 133, 0.15) 100%),
         linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        icon: SunLogo,
        anchorLink: "light",
        label: "Light",
        text: plantData?.overviewLight,
        background: `linear-gradient(113.96deg, rgba(252, 212, 57, 0.15) 0%, rgba(244, 182, 52, 0.15) 100%),
         linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        icon: HumidityLogo,
        anchorLink: "humidity",
        label: "Humidity",
        text: plantData?.overviewMaxHumid,
        background: `linear-gradient(94.5deg, rgba(1, 201, 144, 0.15) 0%, rgba(23, 204, 222, 0.15) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        icon: PetLogo,
        label: "Toxicity",
        text: plantData?.toxic === "TRUE" ? "Toxic" : "Not Toxic",
        background: `linear-gradient(94.5deg, rgba(119, 216, 24, 0.15) 0%, rgba(46, 233, 165, 0.15) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
    ],
    [plantData]
  );

  const plantDetails = useMemo(
    () => [
      {
        id: "water",
        image:
          plantData.imageURL ||
          plantData.imageURL_1 ||
          plantData.imageURL_2 ||
          plantData.imageURL_3 ||
          plantData.imageURL_4,
        // || plantData.imageURL_5
        icon: WaterLogo,
        iconColor: "#07BBE3",
        title: `Watering ${plantData.common_name}`,
        faq: isJsonString(careData?.Care_Water_FAQs)
          ? JsonFunc(careData?.Care_Water_FAQs)
          : [],
        content: [
          {
            heading: "Moisture",
            desc:
              RemoveFirstLastString(
                careData?.Care_WaterDesc_nice_web?.replaceAll("\\n", "")
              ) || plantData?.overviewWaterDesc,
          },
          {
            heading: "Watering",
            desc: plantData?.overviewWaterDesc,
          },
          {
            heading:
              "How To Know If Your Plant is Getting The Right Amount of Water",
            desc: plantData?.overviewWaterDesc,
          },
        ],
        infoCards: [
          {
            icon: BlueCalendar,
            label: `Every ${plantData?.avgWaterDays}d`,
            recommendedText: "Recommended Frequency",
          },
          {
            icon: BlueCup,
            label: "1 /2  cup of water",
            recommendedText: "Recommended Amount",
          },
        ],
      },
      {
        id: "temperature",
        image:
          plantData.imageURL_2 ||
          plantData.imageURL_4 ||
          // plantData.imageURL_5 ||
          plantData.imageURL_1 ||
          plantData.imageURL_3 ||
          plantData.imageURL,
        icon: TempLogo,
        iconColor: "#FC7979",
        title: `Temperature for ${plantData.common_name}`,
        faq: isJsonString(careData?.Care_Temp_FAQs)
          ? JsonFunc(careData?.Care_Temp_FAQs)
          : [],
        content: [
          {
            heading: "Best Temperature",
            desc:
              RemoveFirstLastString(
                careData?.Care_temp_nice_web?.replaceAll("\\n", "")
              ) || plantData?.overviewTempDesc,
          },
        ],
        range: {
          min: 0,
          max: 100,
          start: plantData?.overviewMinTemp,
          end: plantData?.overviewMaxTemp,
        },
      },
      {
        id: "light",
        image:
          plantData.imageURL_2 ||
          plantData.imageURL ||
          plantData.imageURL_1 ||
          plantData.imageURL_3 ||
          plantData.imageURL_4 ||
          plantData.imageURL_5,
        icon: SunLogo,
        iconColor: "#07BBE3",
        title: `Light Requirements for ${plantData.common_name}`,
        faq: isJsonString(careData?.Care_Light_FAQs)
          ? JsonFunc(careData?.Care_Light_FAQs)
          : [],
        content: [
          {
            heading: "Light Requirements",
            desc:
              RemoveFirstLastString(
                careData?.Care_LightDesc_nice_web?.replaceAll("\\n", "")
              ) || plantData?.overviewLightDesc,
          },
        ],
        infoCards: [
          {
            icon: SunOutline,
            label: plantData?.overviewLight,
            recommendedText: "Recommended",
          },
        ],
      },
      {
        id: "humidity",
        image:
          plantData.imageURL_2 ||
          plantData.imageURL_3 ||
          plantData.imageURL ||
          plantData.imageURL_4 ||
          plantData.imageURL_5 ||
          plantData.imageURL_1,
        icon: HumidityLogo,
        iconColor: "#00C9A4",
        title: `Humidity Requirements for ${plantData.common_name}`,
        faq: isJsonString(careData?.Care_Humidity_FAQs)
          ? JsonFunc(careData?.Care_Humidity_FAQs)
          : [],
        content: [
          {
            heading: "Humidity",
            desc:
              RemoveFirstLastString(
                careData?.Care_Humidity_nice_web?.replaceAll("\\n", "")
              ) || plantData?.overviewHumidDesc,
          },
        ],
        progress: plantData?.overviewMaxHumid,
      },
      {
        id: "fertilizing",
        image:
          plantData?.imageURL_4 ||
          plantData?.imageURL_5 ||
          plantData?.imageURL ||
          plantData?.imageURL_1 ||
          plantData?.imageURL_2 ||
          plantData?.imageURL_3,
        icon: FertilizingLogo,
        title: `Fertilizing ${plantData.common_name}`,
        content: [
          {
            heading: "Fertilizing",
            desc:
              RemoveFirstLastString(
                careData?.Care_fertilizer_nice_web?.replaceAll("\\n", "")
              ) || plantData?.fertilizer_desc,
          },
        ],
        faq: isJsonString(careData?.Care_fertilizer_FAQs)
          ? JsonFunc(careData?.Care_fertilizer_FAQs)
          : [],
        infoCards: [
          {
            icon: OrangeCalendar,
            label: `Every ${plantData?.avg_fertilizer_days}d`,
            recommendedText: "Recommended Frequency",
          },
        ],
      },
      {
        id: "repotting",
        image:
          plantData.imageURL_4 ||
          plantData.imageURL ||
          plantData.imageURL_1 ||
          plantData.imageURL_2 ||
          plantData.imageURL_3 ||
          plantData.imageURL_5,
        icon: ReporttingLogo,
        title: `Repotting for ${plantData.common_name}`,
        content: [
          {
            heading: "Repotting Recommendations",
            desc:
              RemoveFirstLastString(
                careData?.Care_potting_mix_type_nice_web?.replaceAll("\\n", "")
              ) || plantData?.repotting_desc,
          },
        ],
        faq: isJsonString(careData?.Care_potting_FAQs)
          ? JsonFunc(careData?.Care_potting_FAQs)
          : [],
        infoCards: [
          {
            icon: YellowCalendar,
            label: `Every ${plantData?.avg_repotting_day}d`,
            recommendedText: "Recommended Frequency",
          },
        ],
      },
      {
        id: "soil",
        image:
          plantData.imageURL_2 ||
          plantData.imageURL_4 ||
          plantData.imageURL ||
          plantData.imageURL_1 ||
          plantData.imageURL_3 ||
          plantData.imageURL_5,
        icon: SoilLogo,
        title: `Best Soil for ${plantData.common_name}`,
        faq: isJsonString(careData?.Care_soil_FAQs)
          ? JsonFunc(careData?.Care_soil_FAQs)
          : [],
        content: [
          {
            heading: "Soil Recommendations",
            desc:
              RemoveFirstLastString(
                careData?.Care_soil_nice_web?.replaceAll("\\n", "")
              ) || plantData?.soil_type_description,
          },
        ],
        infoCards: [
          {
            icon: SoilPot,
            label: plantData?.soil_type,
            recommendedText: "Recommended Soil",
          },
        ],
      },
    ],
    [plantData, careData]
  );

  return (
    <Layout
      title={`An Essential Guide to ${plantData?.common_name} | Flora`}
      description={plantData?.short_desc}
      name={plantData?.common_name}
    >
      <div
        className={`flex flex-col items-center lg:px-0 ${
          SHOW_ANNOUCEMENT_BANNER ? "mt-28" : "mt-20"
        } `}
      >
        <main className="w-full xl:w-[1200px]">
          <div className="fixed w-full z-10 " style={{ bottom: "12px" }}>
            <DownloadAppBar />
          </div>
          <BreadCrumb
            menu={[
              {
                label: "Flora",
                to: "/",
              },
              {
                label: "Plant Care",
                to: "/explore-plants",
              },
              {
                label: plantData?.common_name,
                to: `/explore-plants/${plantData?.slug}`,
              },
              {
                label: "Care Guides",
                to: `/care/how-to-care-for-${plantData?.slug}`,
              },
            ]}
            type={2}
          />
          <section className="flex flex-col py-5 md:flex-row md:items-start px-5 lg:px-0">
            <aside className="p-5 bg-[#F1FFF7] md:w-3/4">
              <div className="flex justify-between">
                <aside className="flex flex-col">
                  <p className="font-avenir800 text-4xl text-body mt-1 mb-1">
                    {plantData?.common_name}
                  </p>
                  <p className="font-avenir900 text-base text-gray">{`${plantData?.common_name} - ${plantData?.plant_types}`}</p>
                </aside>
                {/* <Button
                  frontIcon={WhiteCloud}
                  label="Download Care Guide"
                  className="hidden md:block max-w-md text-white text-xl px-5 py-2 mt-5"
                /> */}
              </div>
              <p className="text-base whitespace-pre-line md:text-base font-avenir400 text-body my-1.5">
                {showFullText
                  ? careData?.Care_introduction_nice?.replaceAll(
                      "\\n",
                      ""
                    ).replaceAll('"', "")
                  : `${careData?.Care_introduction_nice?.slice(0, 830)
                      ?.replaceAll("\\n", "")
                      .replaceAll('"', "")}...`}{" "}
                <button
                  className="text-green hover:underline"
                  onClick={() => setShowFullText(!showFullText)}
                >
                  show {showFullText ? "less" : "more"}
                </button>
              </p>
              {/* <Button
                frontIcon={WhiteCloud}
                label="Download Care Guide"
                className="md:hidden max-w-md text-white text-xl px-5 py-2 mt-5"
              /> */}
            </aside>
            <div className="md:ml-5 mt-2 md:mt-0">
              <LazyLoadImage
                src={plantData?.imageURL}
                alt="image"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = PlantPlaceholder;
                }}
                className="w-full object-cover object-top rounded-lg h-72 mb-4 shadow-lg"
              />
              <aside className="shadow-lg rounded-xl">
                <div className="p-4 flex">
                  <div
                    role="button"
                    tabIndex="0"
                    className="bg-[#F9FCFE] rounded-2xl w-1/3 p-2 shadow-lg hover:shadow-xl cursor-pointer"
                  >
                    <LazyLoadImage
                      tabIndex="0"
                      aria-label="flora_logo"
                      role="button"
                      src={Logo}
                      className="h-full w-full"
                      alt="Flora_Logo"
                      onClick={() => navigate(DOWNLOAD)}
                    />
                  </div>
                  <div className="ml-5">
                    <p
                      role="heading"
                      className="font-avenir800 text-lg font-bold"
                    >
                      Flora - Plant Care and Diagnosis
                    </p>
                    <p
                      role="heading"
                      className="font-avenir400  text-gray text-base "
                    >
                      Watering Schedules & Plant Identification
                    </p>
                  </div>
                </div>
                <div className="flex p-5">
                  <DownloadButtons
                    className="flex-col justify-around md:mr-0"
                    appStoreClass="mt-5 h-12 rounded-xl md:h-16 lg:h-18"
                    playStoreClass=" h-12   rounded-xl md:h-16 lg:h-18"
                  />
                  <LazyLoadImage
                    src={QRCode}
                    className="h-full object-scale-down ml-3"
                  />
                </div>
              </aside>
            </div>
          </section>
          <section className="px-5 lg:px-0">
            <p className="text-lg font-avenir800">Move to section</p>
            <div className="grid grid-cols-3 gap-5 mt-2 md:grid-cols-6 md:py-4">
              {badges.map((badge, index) => (
                <MemberBadge
                  key={`badge-${index}`}
                  item={badge}
                  background={badge?.background}
                  anchorLink={badge?.anchorLink}
                />
              ))}
            </div>
          </section>
          <CareCard plantDetails={plantDetails} plantData={plantData} />
        </main>
      </div>
      {/* {plantData?.Immersion_FAQ_Answer_1 && <FAQS plantData={plantData} />} */}
      {careData?.Care_common_issue_1_nice_web && (
        <CommonDiseases plant={careData} />
      )}
      <ShopPlants
        plantName={careData?.common_name}
        selectedPlant={props?.data?.allShopifyProduct?.edges}
      />
      <RelatedPlants
        customPlants={[]}
        paragraph={careData?.Immersion_related_plants_standard_web}
      />
      <div className="px-5 py-5 flex  flex-col items-center md:py-10">
        <div className="w-full xl:w-[1200px]">
          <p className="text-2xl pb-4">Other Articles:</p>
          <div className="flex md:grid overflow-scroll w-full  grid-cols-1 px-0 md:grid-cols-2  lg:grid-cols-4 gap-6 md:px-5 lg:px-0 lg:py-5">
            {(blogs || []).map((post, index) => (
              <div
                key={index}
                onClick={() => navigate(`/blogs/${post?.slug?.toLowerCase()}/`)}
                className="shrink-0 cursor-pointer w-[16rem]"
              >
                <LazyLoadImage
                  className="w-80 h-48 rounded-xl object-cover md:w-full hover:scale-95 transition duration-200 cursor-pointer"
                  src={post?.coverImage?.url}
                  alt={post?.coverImage?.fileName || "blog-feature-img"}
                />
                <p className="text-avenir900 text-base w-full pt-3 font-bold md:text-xl">
                  {post?.title}
                </p>
                <p className="text-[#2D3344] font-light mt-2">
                  {moment(post?.date).format("MMM DD, YYYY")}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query CarePlantBySlug($slug: String!) {
    allCarePlants(filter: { plant_name_dash: { eq: $slug } }) {
      edges {
        node {
          imageURL
          common_name
          Immersion_FAQ_Answer_1
          Care_introduction_nice
          Immersion_FAQ_Answer_1
          Immersion_FAQ_Answer_2
          Immersion_FAQ_Answer_3
          Immersion_FAQ_Answer_4
          Immersion_FAQ_Answer_5
          Immersion_FAQ_Question_1
          Immersion_FAQ_Question_2
          Immersion_FAQ_Question_3
          Immersion_FAQ_Question_4
          Immersion_FAQ_Question_5
          Immersion_summary_web
          Immersion_WaterDesc_nice_web
          Immersion_fertilizer_nice_web
          Immersion_LightDesc_nice_web
          Immersion_Humidity_nice_web
          Immersion_pruning_nice_web
          Immersion_repotting_nice_web
          Immersion_soil_nice_web
          Immersion_Temp_nice_web
          Immersion_related_plants_standard_web
          Immersion_toxicity_dog_standard_web
          Immersion_toxicity_cat_standard_web
          Immersion_toxicity_human_standard_web
          Care_fertilizer_nice_web
          Care_related_plants_standard_web
          Care_soil_nice_web
          Care_potting_mix_type_nice_web
          Care_Humidity_nice_web
          Care_temp_nice_web
          Care_LightDesc_nice_web
          Care_WaterDesc_nice_web
          Care_introduction_nice
          Care_Humidity_FAQs
          Care_Water_FAQs
          Care_Temp_FAQs
          Care_potting_FAQs
          Care_soil_FAQs
          Care_fertilizer_FAQs
          Care_Light_FAQs
          Care_common_issue_1_nice_web
          Care_common_issue_2_nice_web
          Care_common_issue_3_nice_web
          Care_common_issue_4_nice_web
        }
      }
    }
    allPlants(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          common_name
          imageURL
          imageURL_1
          imageURL_2
          imageURL_3
          imageURL_4
          plant_types
          overviewLightDesc
          avgWaterDays
          short_desc
          overviewMinTemp
          overviewMaxTemp
          overviewLight
          overviewHumidDesc
          overviewMaxHumid
          overviewTempDesc
          overviewWaterDesc
          overviewLevel
          overviewWaterAmount
          soil_type_description
          soil_type
          repotting_desc
          fertilizer_desc
          avg_fertilizer_days
          repotting_desc
          avg_repotting_day
          toxic
          genus
          family
          slug
          family
          scientific_name
        }
      }
    }
    allGraphCmsPost(limit: 8) {
      edges {
        node {
          title
          slug
          date
          coverImage {
            url
          }
        }
      }
    }
  }
`;

export default Care;
