import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import PlantPlaceholder from "../../../images/PlantPlaceholder.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../../../images/icons/newLogo.svg";
import { GET_APP } from "../../../assets/Links";
import { navigate } from "gatsby";
const CareCard = ({ plantDetails }) => {
  return (
    <>
      {plantDetails?.map((plant, index) => {
        return (
          <section
            className="py-5 px-5 lg:px-0 lg:py-10"
            key={index}
            id={plant?.id}
          >
            <section className="flex items-center">
              <StaticImage
                className="h-7 w-7 mr-2"
                src="../../images/icons/bookBold.png"
              />
              <h2 className="text-xl font-avenir800 font-semibold md:text-2xl">
                {plant?.title}
              </h2>
            </section>
            <section className="py-5 md:py-10">
              <aside className="flex flex-col md:flex-row">
                <div className="flex flex-col  md:pr-5 md:w-3/4">
                  {plant?.infoCards && (
                    <div className="flex gap-4 mb-5">
                      {plant?.infoCards?.map((view, viewIndex) => {
                        return (
                          <div
                            className="flex mb-2 shadow-lg p-2 rounded-xl"
                            key={viewIndex}
                          >
                            <LazyLoadImage
                              src={view?.icon}
                              alt="Chevron icon"
                              className="inline-block mr-2"
                            />
                            <div>
                              <p className="font-avenir800 text-body text-sm">
                                {view?.label}
                              </p>
                              <p className="font-avenir900 text-gray text-xs">
                                {view?.recommendedText}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="text-lg p-5 shadow-lg rounded-xl h-full md:p-7">
                    <section className="flex items-center pb-3">
                      <LazyLoadImage
                        className="h-6 w-6 mr-2"
                        src={plant?.icon}
                      />
                      <h2 className="text-xl font-avenir800 font-semibold">
                        {plant?.content?.[0]?.heading}
                      </h2>
                    </section>
                    {plant?.range && (
                      <div className="w-full text-center mb-6">
                        <div className="flex flex-row justify-between align-center">
                          <span className="text-gray font-avenir400 text-xs">
                            {Math.abs(plant?.range?.min)}°
                          </span>
                          <span className="text-gray font-avenir400 text-xs">
                            {Math.abs(plant?.range?.max / 2)}°
                          </span>
                          <span className="text-gray font-avenir400 text-xs">
                            {Math.abs(plant?.range?.max)}°
                          </span>
                        </div>
                        <div className="mb-1 w-full h-5 bg-lightGray/30 rounded-full ">
                          <div
                            className="h-full text-center text-xs font-avenir900 text-white rounded-full py-1.5"
                            style={{
                              marginLeft: `${
                                ((plant?.range?.start - plant?.range?.min) /
                                  (plant?.range?.max - plant?.range?.min)) *
                                  100 -
                                5
                              }%`,
                              marginRight: `${
                                100 -
                                ((plant?.range?.end - plant?.range?.min) /
                                  (plant?.range?.max - plant?.range?.min)) *
                                  100 -
                                5
                              }%`,
                              backgroundColor: plant?.iconColor,
                            }}
                          >
                            {`${plant?.range?.start}° - ${plant?.range?.end}°`}
                          </div>
                        </div>
                      </div>
                    )}
                    {plant?.progress && (
                      <div className="mb-6 w-full h-5 bg-lightGray/30 rounded-full">
                        <div
                          className="h-full text-center text-xs font-avenir900 text-white rounded-full py-1.5"
                          style={{
                            width: `${plant?.progress}%`,
                            backgroundColor: plant?.iconColor,
                          }}
                        >
                          {`${plant?.progress}%`}
                        </div>
                      </div>
                    )}
                    <p className="text-base text-avenir400 leading-relaxed whitespace-pre-line">
                      {plant?.content?.[0]?.desc}
                    </p>
                  </div>
                </div>
                <LazyLoadImage
                  src={plant?.image}
                  alt="image"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = PlantPlaceholder;
                  }}
                  className="object-cover w-full md:w-1/4 rounded-2xl mt-5 md:mt-0 md:max-w-md"
                />
              </aside>
            </section>
            <section className="grid  md:grid-cols-3 gap-4">
              {plant?.faq &&
                // Array.isArray(plant.faq) &&
                plant?.faq?.length > 0 &&
                plant?.faq?.map?.((item, index) => (
                  <main
                    className="flex flex-col justify-between shadow-lg rounded-xl  p-5"
                    key={plant?.title + index}
                  >
                    <div className="text-base  h-full">
                      <section className="flex items-center pb-3">
                        <img
                          className="h-6 w-6 mr-2"
                          src={plant?.icon}
                          alt="icon"
                        />
                        <h2 className="text-lg font-avenir800 font-semibold">
                          {item?.question}
                        </h2>
                      </section>
                      <p className="text-base ">{item?.answer}</p>
                    </div>
                    <aside className="flex justify-end pt-4 px-2">
                      <button
                        onClick={() => navigate(GET_APP)}
                        className="bg-[#D6FEEB] text-[#23D173] px-5 py-1.5 rounded-full"
                      >
                        <div className="flex jusitfy-center">
                          <LazyLoadImage
                            src={Logo}
                            className="mr-2 mb-1 h-[24px] w-[24px]"
                          />
                          <p>Check the App</p>
                        </div>
                      </button>
                    </aside>
                  </main>
                ))}
            </section>
          </section>
        );
      })}{" "}
    </>
  );
};

export default CareCard;
